<template>
  <div class="minePage">
    <div class="mineMain">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="刷新成功">
        <div class="userInfoBox">
          <ImgDecypt class="avatarImg" round :src="userInfo.portrait" :key="userInfo.portrait" />
          <div class="userNameBox">
            <div class="userName">{{ userInfo.name }}</div>
            <div class="userId" @click="doCopy">ID: {{ userInfo.uid }}</div>
          </div>
          <div class="optBox">
            <router-link tag="div" to="/kefu">
              <svg-icon class="optIcon" iconClass="home_kefu" />
            </router-link>
            <router-link tag="div" to="/personalInfo">
              <svg-icon class="optIcon" iconClass="home_setting" />
            </router-link>
          </div>
        </div>

        <router-link tag="div" to="/memberCentre" class="userData">
          <div class="userDataItem">
            <div class="dataTitle">每日观影次数</div>
            <div class="dataValue">{{ userInfo.watchCount }}/{{ userInfo.allWacthCount }}</div>
          </div>
          <div class="userDataItem">
            <div class="dataTitle">返利比例</div>
            <div class="dataValue">{{ userInfo.depositRebate || 0 }}%</div>
          </div>
          <div class="userDataItem">
            <div class="dataTitle">{{ userInfo.isVip ? '当前会员等级:' : '你还不是会员' }}</div>
            <div class="dataValue notVip" v-if="!userInfo.isVip"></div>
            <div v-else>
              <div class="dataValue vipLevel1" v-if="userInfo.vipLevel == 1"></div>
              <div class="dataValue vipLevel2" v-else-if="userInfo.vipLevel == 2"></div>
              <div class="dataValue vipLevel3" v-else-if="userInfo.vipLevel == 3"></div>
              <div class="dataValue vipLevel4" v-else-if="userInfo.vipLevel == 4"></div>
              <div class="dataValue vipLevel5" v-else-if="userInfo.vipLevel == 5"></div>
              <div class="dataValue vipLevel6" v-else-if="userInfo.vipLevel == 6"></div>
              <div class="dataValue vipLevel7" v-else-if="userInfo.vipLevel == 7"></div>
            </div>
          </div>
        </router-link>

        <div class="balanceBox">
          <div class="balanceInfo">
            <div class="balanceTitle">
              我的余额<span>(1游戏币=1¥)</span>
              <div class="hiddenBtn" @click="hiddenBalance = !hiddenBalance">
                <svg-icon class="openEye" :iconClass="hiddenBalance ? 'closeEye' : 'openEye'" />
              </div>
            </div>
            <div class="balance">
              <span>¥</span>
              {{ hiddenBalance ? '****' : $store.getters.userInfo.amount }}
              <div class="refreshBox" @click="refreshAmount">
                <svg-icon class="refreshBtn" iconClass="refreshBtn" id="refresh" />
              </div>
            </div>
          </div>
          <div class="balanceOpt">
            <router-link tag="div" to="/myWallet" class="deposit"></router-link>
            <div class="withdraw" @click="toWithdraw"></div>
          </div>
        </div>

        <div class="kingkongBox">
          <router-link tag="div" to="/memberCentre" class="kingkongItem">
            <svg-icon class="kingkongIcon" iconClass="memberIcon" />
            <div>会员中心</div>
          </router-link>
          <router-link tag="div" to="/sharePromote" class="kingkongItem">
            <svg-icon class="kingkongIcon" iconClass="promoteIcon" />
            <div>邀请推广</div>
          </router-link>
          <router-link tag="div" to="/exchangeMember" class="kingkongItem">
            <svg-icon class="kingkongIcon" iconClass="exchangeIcon" />
            <div>兑换中心</div>
          </router-link>
          <router-link tag="div" to="/actCredentials" class="kingkongItem">
            <svg-icon class="kingkongIcon" iconClass="certificateIcon" />
            <div>账号凭证</div>
          </router-link>
        </div>

        <div class="cellList">
          <div class="cellItem" @click="toKefu">
            <svg-icon class="cellIcon" iconClass="kefu" />
            <div class="cellTitle">VIP专属客服</div>
            <svg-icon class="cellRightArrow" iconClass="cellRightArrow" />
          </div>
          <router-link tag="div" to="/historyRecord" class="cellItem">
            <svg-icon class="cellIcon" iconClass="videoHistory" />
            <div class="cellTitle">观看记录</div>
            <svg-icon class="cellRightArrow" iconClass="cellRightArrow" />
          </router-link>
          <router-link tag="div" to="/myCollection" class="cellItem">
            <svg-icon class="cellIcon" iconClass="videoIcon" />
            <div class="cellTitle">我的收藏</div>
            <svg-icon class="cellRightArrow" iconClass="cellRightArrow" />
          </router-link>
          <router-link tag="div" to="/boutiqueApp" class="cellItem">
            <svg-icon class="cellIcon" iconClass="applicationIcon" />
            <div class="cellTitle">精品应用</div>
            <svg-icon class="cellRightArrow" iconClass="cellRightArrow" />
          </router-link>
          <div class="cellItem" @click="jumpCooperate">
            <svg-icon class="cellIcon" iconClass="cooperateIcon" />
            <div class="cellTitle">商务合作</div>
            <svg-icon class="cellRightArrow" iconClass="cellRightArrow" />
          </div>
          <router-link tag="div" to="/officialCommunity" class="cellItem">
            <svg-icon class="cellIcon" iconClass="groupIcon" />
            <div class="cellTitle">官方群组</div>
            <svg-icon class="cellRightArrow" iconClass="cellRightArrow" />
          </router-link>
        </div>
        <div class="bottomBox"></div>
      </van-pull-refresh>
    </div>
    <!-- 账号凭证弹窗 -->
    <van-overlay :show="showActTip" z-index="2000">
      <div class="wrapper">
        <div class="actTipBox">
          <div class="info">为了避免账号丢失，请保存账号凭证！</div>
          <div class="actTipBtn">
            <div class="btn" @click="saved">我已保存</div>
            <div class="btn pink" @click="$router.push('/actCredentials')">立即保存</div>
          </div>
          <div class="close" @click="showActTip = !showActTip">
            <svg-icon icon-class="close4" class="closeIcon" />
          </div>
        </div>
      </div>
    </van-overlay>
    <MemberBuy :show="isVipbuyShow" @close="isVipbuyShow = false" />
  </div>
</template>

<script>
import { Grid, GridItem, Overlay, PullRefresh, Toast } from 'vant';
import { mapGetters } from 'vuex';
import { gameBalance } from '@/api/game';
import ImgDecypt from '@/components/ImgDecypt';
import MemberBuy from '@/components/MemberBuy';

export default {
  name: 'mine',
  components: {
    ImgDecypt,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Overlay.name]: Overlay,
    [PullRefresh.name]: PullRefresh,
    MemberBuy,
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      unreadMessages: 'unreadMessages',
      isVip: 'isVip',
    }),
  },
  activated() {
    this.$store.dispatch('user/getUserInfo');
  },
  data() {
    return {
      showActTip: localStorage.getItem('isCertificate') == 0 ? false : true,
      isLoading: false,
      hiddenBalance: false,
      cooperation: '',
      lock: false, // 刷新金额的锁
      isVipbuyShow: false,
      serviceGroupLink: '', // 客服链接
    };
  },
  created() {
    this.$store.dispatch('user/getUserInfo');
    let appConfig = this.$store.getters.appConfig;
    appConfig.sourceList.forEach((i) => {
      if (i.type == 'BusinessCooperation') {
        this.cooperation = i.domain[0].url;
      }
    });
    appConfig.sourceList.forEach((i) => {
      if (i.type == 'ServiceGroupLink') {
        this.serviceGroupLink = i.domain[0].url;
      }
    });
  },
  methods: {
    async refreshAmount() {
      if (this.lock) return Toast('请勿频繁操作');
      this.lock = true;
      let svg = document.querySelector('#refresh');
      svg.classList.add('refresh-icon');
      try {
        let ret = await this.$Api(gameBalance);
        if (ret && ret.code == 200) {
          let userInfo = this.$store.getters.userInfo;
          userInfo.amount = ret.data.amount;
          this.$store.commit('user/SET_USERINFO', userInfo);
          Toast('余额刷新成功');
        } else {
          Toast(ret.msg || '余额刷新失败');
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.lock = false;
        svg.classList.remove('refresh-icon');
      }
    },
    jumpCooperate() {
      window.open(this.cooperation);
    },
    async onRefresh() {
      await this.$store.dispatch('user/getUserInfo');
      this.isLoading = false;
    },
    saved() {
      localStorage.setItem('isCertificate', '0');
      this.showActTip = false;
    },
    doCopy() {
      this.$copyText(this.userInfo.uid.toString()).then(
        function (e) {
          Toast('复制成功');
        },
        function (e) {
          Toast('复制失败');
        },
      );
    },
    toWithdraw() {
      let mobile = this.$store.getters.userInfo.mobile;
      if (!mobile) return Toast('请先绑定手机号');
      this.$router.push('/withdraw');
    },
    toKefu() {
      if (!this.isVip) return (this.isVipbuyShow = true);
      window.open(this.serviceGroupLink);
    },
  },
};
</script>

<style lang="scss" scoped>
.minePage {
  height: 100%;
  background: #fafafa;
  .mineMain {
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 51, 153, 0.1), rgba(255, 134, 90, 0.02));
    background-repeat: no-repeat;
    background-size: 100% 123px;
    padding: 0 20px;
    box-sizing: border-box;
    /deep/ .van-pull-refresh {
      height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
    .userInfoBox {
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .avatarImg {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
      .userNameBox {
        flex: 2;
        margin: 0 13px;
        .userName {
          font-size: 14px;
          color: #333333;
        }
        .userId {
          // margin-top: 2px;
          font-size: 10px;
          color: #797979;
        }
      }
      .optBox {
        display: flex;
        align-items: center;
        .optIcon {
          width: 29px;
          height: 29px;
        }
        .optIcon {
          margin-left: 10px;
        }
      }
    }
    .userData {
      // width: 335px;
      height: 70px;
      margin-top: 9px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #f74c82;
      font-size: 12px;
      border-radius: 10px;
      background-image: linear-gradient(to bottom, rgba(253, 104, 152, 0.13), rgba(255, 215, 198, 0.2));
      .userDataItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .dataTitle {
          margin-bottom: 2px;
        }
        .dataValue {
          height: 21px;
          margin-top: 5px;
        }
        .notVip {
          width: 70px;
          height: 21px;
          background: url('../../assets/png/notVip.png');
          background-size: 100% 100%;
        }
        .vipLevel1 {
          width: 90.5px;
          height: 21px;
          background: url('../../assets/png/vipLevel1.png');
          background-size: 100% 100%;
        }
        .vipLevel2 {
          width: 90.5px;
          height: 21px;
          background: url('../../assets/png/vipLevel2.png');
          background-size: 100% 100%;
        }
        .vipLevel3 {
          width: 90.5px;
          height: 21px;
          background: url('../../assets/png/vipLevel3.png');
          background-size: 100% 100%;
        }
        .vipLevel4 {
          width: 90.5px;
          height: 21px;
          background: url('../../assets/png/vipLevel4.png');
          background-size: 100% 100%;
        }
        .vipLevel5 {
          width: 90.5px;
          height: 21px;
          background: url('../../assets/png/vipLevel5.png');
          background-size: 100% 100%;
        }
        .vipLevel6 {
          width: 90.5px;
          height: 21px;
          background: url('../../assets/png/vipLevel6.png');
          background-size: 100% 100%;
        }
        .vipLevel7 {
          width: 90.5px;
          height: 21px;
          background: url('../../assets/png/vipLevel7.png');
          background-size: 100% 100%;
        }
      }
    }
    .balanceBox {
      height: 78px;
      background: #fff;
      border-radius: 10px;
      margin-top: 10px;
      padding: 0 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .balanceInfo {
        .balanceTitle {
          display: flex;
          align-items: center;
          font-size: 14px;
          span {
            font-size: 10px;
            color: rgb(149,149,149);
            margin-left: 4px;
          }
        }
        .hiddenBtn {
          margin-left: 4px;
        }
      }
      .balance {
        font-size: 20px;
        color: #f84e85;
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          margin-right: 2px;
        }
        .refreshBox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 1px 0 0 10.6px;
        }
        .refreshBtn {
          width: 16px;
          height: 16px;
        }
      }
      .balanceOpt {
        display: flex;
        align-items: center;
        .deposit {
          width: 76px;
          height: 37px;
          background: url('../../assets/png/deposit1.png');
          background-size: 100% 100%;
          margin-right: 10px;
        }
        .withdraw {
          width: 76px;
          height: 37px;
          background: url('../../assets/png/withdraw1.png');
          background-size: 100% 100%;
        }
      }
    }
    .kingkongBox {
      height: 99px;
      border-radius: 0 0 10px 10px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 11px;
      .kingkongItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        color: #797979;
        .kingkongIcon {
          width: 44px;
          height: 44px;
          margin-bottom: 4px;
        }
      }
    }
    .cellList {
      margin-top: 12px;
      padding: 21px 12px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;
      .cellItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        .cellIcon {
          width: 23px;
          height: 23px;
        }
        .cellTitle {
          flex: 2;
          margin: 0 7px;
          font-size: 10px;
          color: #797979;
        }
      }
      .cellItem:last-child {
        margin-bottom: 0;
      }
    }
    .bottomBox {
      height: 100px;
    }
    .memberWallet {
      display: flex;
      justify-content: space-between;
      margin: 6px 0 6px;
      padding: 7px 12px;
      background: #fff;
      .box {
        padding: 0 14px;
        display: flex;
        align-items: center;
        background-color: rgb(255, 235, 241);
        border-radius: 4px;
        height: 64px;
        .icon {
          height: 32px;
          width: 32px;
        }
        .indro {
          margin-left: 6px;
          font-size: 11.5px;
          color: rgb(255, 88, 132);
          .title {
            font-size: 14px;
            font-weight: 800;
          }
        }
      }
      .leftBox {
        flex: 1;
      }
      .rightBox {
        width: 122px;
        margin-left: 10px;
      }
    }
    .funcBox {
      background: rgb(255, 255, 255);
      margin-bottom: 10px;
      color: rgb(51, 51, 51);
      padding: 5px 15px;
      ::deep .minPage {
        padding: unset;
      }
      .funcItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        /deep/ .van-grid-item__content {
          background: none;
          padding: 12px 0px;
        }
        .funcIcon {
          width: 38px;
          height: 38px;
          margin-bottom: 4px;
        }
        .funcTitle {
          font-size: 14px;
        }
        .dot {
          height: 10px;
          width: 10px;
          background-color: red;
          border-radius: 50%;
          position: absolute;
          right: 30px;
          top: 10px;
        }
      }
    }
    .cellBox {
      background-color: #fff;
      padding: 16px 26px 6px;
      .cell {
        height: 41px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .cellIcon {
          width: 24px;
          height: 24px;
        }
        .cellTitle {
          flex: 2;
          margin-left: 10px;
          font-size: 16px;
          font-weight: 500;
          color: rgb(51, 51, 51);
        }
        .right_arrow {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
.refresh-icon {
  transform: rotate(180deg);
  transition: transform 0.6s ease;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .actTipBox {
    width: 288px;
    height: 259px;
    background: url('../../assets/png/actTip.png') no-repeat;
    background-size: 100% 100%;
    text-align: center;
    position: relative;
    .info {
      width: 100%;
      font-size: 14px;
      color: rgb(68, 68, 68);
      position: absolute;
      top: 112px;
    }
    .actTipBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 24px;
      box-sizing: border-box;
      position: absolute;
      bottom: 40px;
      .btn {
        width: 113px;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        border-radius: 10px;
        color: rgb(247, 86, 130);
        border: 1.5px solid rgb(247, 86, 130);
      }
      .pink {
        background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
        color: white;
        border: unset;
      }
    }
    .close {
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translate(-50%, 0);
      .closeIcon {
        height: 36px;
        width: 36px;
      }
    }
  }
}
</style>
