<template>
  <div class="vip_buy_warp">
    <van-overlay :show="show">
      <div class="vip_buy_box">
        <div class="vip_buy">
          <p>您当前还不是VIP，请充值VIP</p>
          <router-link tag="div" to="/memberCentre" class="btn">充值VIP</router-link>
        </div>
        <div class="close" @click="$emit('close')">
          <svg-icon icon-class="close4" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Overlay } from 'vant';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    [Overlay.name]: Overlay,
  },
};
</script>
<style lang="scss" scoped>
.vip_buy_warp {
  /deep/ .van-overlay {
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vip_buy_box {
    .vip_buy {
      height: 259px;
      width: 288px;
      background: url('./../../assets/png/buyMemberBg.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 14px;
      padding-top: 105px;
      box-sizing: border-box;
      p {
        font-weight: 600;
        font-size: 14px;
        color: rgb(68, 68, 68);
      }
      .btn {
        height: 38px;
        width: 173px;
        border-radius: 5px;
        background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
        font-size: 14px;
        color: white;
        margin: 51px auto;
        line-height: 38px;
      }
    }
    .close {
      font-size: 32px;
      margin: 20px auto;
      text-align: center;
    }
  }
}
</style>
